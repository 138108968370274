import "./style.scss";

import { graphql } from "gatsby";
import { useTranslation } from "react-i18next/dist/es/useTranslation";

import Banner from "../../components/AccentBanner/Banner";
import Container from "../../components/Container";
import ContentWithSidebar from "../../components/Container/_contentWithSidebar";
import Heading from "../../components/Heading";
import Layout from "../../layouts";
import LinkOnPage from "../../components/Links/LinkOnPage";
import Mark from "../../components/Marker";
import Meta from "../../components/Post/_meta";
import Paragraph from "../../components/Paragraph";
import ScrollUp from "../../components/ScrollUp";
import Sidebar from "../../components/Sidebar";
import SubscribeForm from "../../components/Forms/_subscribe";
import Title from "../../components/Title";
import { createClassName } from "../../helpers";
import { OnlineSalesImage } from "../../components/OnlineSales/Image";

const cn = createClassName("blog");

const sidebarPost = (item, slug, index) => (
  <div key={index} className={cn("sidebar", { link: "wrap" })}>
    <LinkOnPage
      className={cn("sidebar", { link: "heading" })}
      href={slug}
      color="default"
      withoutLocale
    >
      {item.bannerTitle}
    </LinkOnPage>
    <div className={cn("sidebar", { link: "date" })}>
      {item.defaultData[0].dataList.date}
    </div>
  </div>
);

const sidebarTitle = text => (
  <Title level={5} weight="medium" className={cn("sidebar", { title: true })}>
    {text}
  </Title>
);

const Post = props => {
  const { index, slug, ...item } = props;
  return (
    <article aria-label={props.title}>
      <section className={cn("post", { section: true })}>
        <LinkOnPage
          className={cn("post", {
            link: true,
            img: index === 0 ? "wrap" : "next-post",
          })}
          href={slug}
          color="default"
          withoutLocale
        >
          <OnlineSalesImage
            onlineSalesImage={item.coverImage}
            alt={item.coverImageAlt}
            className={cn("post", { img: true })}
          />
        </LinkOnPage>
        <div
          className={cn("post", { content: true, "next-post": index !== 0 })}
        >
          <Mark
            text={item.category}
            className={cn("post", { category: true })}
          />
          <LinkOnPage
            className={cn("post", { link: true })}
            href={slug}
            color="default"
            withoutLocale
          >
            <Heading
              level={3}
              fontLevel={4}
              weight="medium"
              className={cn("post", {
                title: index === 0 ? true : "next-post",
              })}
            >
              {item.bannerTitle}
            </Heading>
          </LinkOnPage>
          <Meta
            meta={item.defaultData?.[0]?.dataList || {}}
            className={cn("post", { date: true })}
            itemClassName={cn("post", { date: "data" })}
            whiteIcon={false}
          />
          {item.preview && (
            <LinkOnPage
              className={cn("post", { link: true })}
              href={slug}
              color="default"
              withoutLocale
            >
              {index === 0 && (
                <Paragraph className={cn("post", { preview: true })}>
                  {item.preview}
                </Paragraph>
              )}
            </LinkOnPage>
          )}
        </div>
      </section>
    </article>
  );
};

const Blog = ({ data }) => {
  const { t } = useTranslation("forms");
  const nodes = data.posts.edges;
  const { frontmatter } = data.blogPage;

  const sidebarContent = (
    <>
      {sidebarTitle(frontmatter.sidebarTitle)}
      {nodes.map(
        ({ node }, index) =>
          node.childMarkdownRemark.frontmatter.featured &&
          sidebarPost(node.childMarkdownRemark.frontmatter, node.slug, index)
      )}
      <div className={cn("sidebar", { "subscribe-form": true })}>
        <Title level={4}>{t("forms:miniForm.heading")}</Title>
        <Paragraph
          bottomIndent
          className={cn("sidebar", { "subscribe-form": "description" })}
        >
          {t("forms:miniForm.description.forFooter")}
        </Paragraph>
        <SubscribeForm />
      </div>
    </>
  );

  return (
    <Layout {...frontmatter.seo[0]}>
      <Banner
        title={frontmatter.bannerTitle}
        subtitle={frontmatter.bannerSubTitle}
        subtitleIsHeading
        titleClassName={cn("banner", { title: true })}
      />
      <section aria-label="Blog">
        <Container className={cn({ wrap: true })}>
          <Sidebar className={cn("sidebar", { wrap: true })}>
            {sidebarContent}
          </Sidebar>
          <ContentWithSidebar>
            {nodes.map(({ node }, index) => (
              <Post
                key={index}
                index={index}
                slug={node.slug}
                coverImage={node.coverImage}
                {...node.childMarkdownRemark.frontmatter}
              />
            ))}
          </ContentWithSidebar>
          <div className={cn("sidebar", { mobile: true })}>
            {sidebarContent}
          </div>
        </Container>
      </section>
      <ScrollUp />
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query getPostsForBlog($siteLanguage: String!, $path: String!) {
    posts: allOnlineSalesPost(
      filter: { language: { eq: $siteLanguage } }
      sort: { order: DESC, fields: childMarkdownRemark___frontmatter___date }
    ) {
      totalCount
      edges {
        node {
          slug
          coverImage {
            file {
              childImageSharp {
                gatsbyImageData(
                  width: 600
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  quality: 100
                )
              }
            }
          }
          childMarkdownRemark {
            frontmatter {
              defaultData {
                dataList {
                  date
                  time
                }
              }
              language
              category
              bannerTitle
              coverImageAlt
              preview
              featured
            }
          }
        }
      }
    }
    blogPage: markdownRemark(frontmatter: { path: { eq: $path }, language: { eq: $siteLanguage } }) {
      frontmatter {
        seo {
          description
          link
          title
        }
        bannerTitle
        bannerSubTitle
        sidebarTitle
      }
    }
  }
`;
